import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ValidationElement } from "../../api/types";
import { useState } from "react";
import {
  reportErrorColor,
  warningColor,
  errorColor,
  inconsistencyColor,
  infoColor,
  internalValidationErrorIds,
} from "../../helpers/constants";
import { dataKey } from "../../helpers/downloadPageAsPdf";
import { t } from "@lingui/macro";
import ErrorElementIcons from "./errorElementIcons";
import { getUniqueErrorMessages } from "../../helpers/getUniqueErrorMessages";
import { TableCellSeverity } from "./validationSummaryComponents/tableCellSeverity";
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

type PickOnly = "FatalError" | "Error" | "Warning" | "Info" | "Inconsistency";
export interface ErrorShowCaseProps {
  title: string;
  data: Array<ValidationElement>;
  shouldCombine: boolean;
  showCalcInconsistencyToggle?: boolean;
}

type RenderSeverityProps = {
  count: number;
  name: PickOnly;
  color: string;
};

const renderSeverity = ({ count, name, color }: RenderSeverityProps) =>
  count > 0 && (
    <Typography
      variant="h3"
      sx={{
        color,
        pr: 0.5,
        pl: 0.5,
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: "0.25px",
      }}
      display="inline"
    >
      {moreThanOneError(count, name)}
    </Typography>
  );

export const ErrorShowCase = ({
  title,
  data,
  shouldCombine,
  showCalcInconsistencyToggle,
}: ErrorShowCaseProps) => {
  const [hideErrors, setHideErrors] = useState(false);
  const [hideWarnings, setHideWarnings] = useState(false);
  const [hideRoundingErrors, setHideRoundingErrors] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const combinedData = shouldCombine ? getUniqueErrorMessages(data) : data;

  const counts = combinedData.reduce(
    (acc, { severity, isRoundingError }) => {
      if (severity === "FatalError") acc.FatalError++;
      if (severity === "Error") acc.Error++;
      if (severity === "Warning") acc.Warning++;
      if (
        severity === "Inconsistency" &&
        (!hideRoundingErrors || (hideRoundingErrors && !isRoundingError))
      )
        acc.Inconsistency++;
      if (severity === "Info") acc.Info++;
      return acc;
    },
    { FatalError: 0, Error: 0, Warning: 0, Inconsistency: 0, Info: 0 }
  );

  const pdfGeneratorDataKey = dataKey(title);

  const filteredData = combinedData
    .filter((entry) => (hideErrors ? entry.severity !== "Error" : true))
    .filter((entry) => (hideWarnings ? entry.severity !== "Warning" : true))
    .filter(
      (entry) =>
        !hideRoundingErrors || (hideRoundingErrors && !entry.isRoundingError)
    )
    .sort((a, b) => {
      if (a.severity === "Error" && b.severity === "Warning") return -1;
      if (a.severity === "Warning" && b.severity === "Error") return 1;
      return 0;
    });

  const fontSizeIcons = 24;

  const severityIconSelector = (severity: PickOnly) => {
    const color = reportErrorColor(severity);
    switch (severity) {
      case "FatalError":
        return <PriorityHighIcon sx={{ fontSize: fontSizeIcons, color: { color } }} />;
      case "Error":
        return <ErrorIcon sx={{ fontSize: fontSizeIcons, color: { color } }} />;
      case "Warning":
        return <WarningIcon sx={{ fontSize: fontSizeIcons, color: { color } }} />;
      case "Inconsistency":
        return <WarningIcon sx={{ fontSize: fontSizeIcons, color: { color } }} />;
      case "Info":
        return <InfoIcon sx={{ fontSize: fontSizeIcons, color: { color } }} />;
      default:
        return null;
    }
  }

  return data.length === 0 ? null : (
    <Accordion expanded={expanded} sx={{ overflowX: "hidden", overflowY: "auto", mb: 2 }}>
      <AccordionSummary
        sx={{ zIndex: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}
        onClick={() => setExpanded(!expanded)}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "26px",
            letterSpacing: "0.25px",
          }}
          data-downloadaspdf={`${pdfGeneratorDataKey}header`}
        >
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {renderSeverity({ count: counts.FatalError, name: "FatalError", color: errorColor })}
          {counts.FatalError > 0 && counts.Error > 0 && (
            <Typography
              variant="h3"
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "26px",
                letterSpacing: "0.25px",
              }}
            >
              and
            </Typography>
          )}
          {renderSeverity({ count: counts.Error, name: "Error", color: errorColor })}
          {counts.Error > 0 && counts.Warning > 0 && (
            <Typography
              variant="h3"
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "26px",
                letterSpacing: "0.25px",
              }}
            >
              and
            </Typography>
          )}
          {renderSeverity({ count: counts.Warning, name: "Warning", color: warningColor })}
          {counts.Warning > 0 && counts.Inconsistency > 0 && (
            <Typography
              variant="h3"
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "26px",
                letterSpacing: "0.25px",
              }}
            >
              and
            </Typography>
          )}
          {renderSeverity({ count: counts.Inconsistency, name: "Inconsistency", color: inconsistencyColor })}
          {counts.Inconsistency > 0 && counts.Info > 0 && (
            <Typography
              variant="h3"
              sx={{
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "26px",
                letterSpacing: "0.25px",
              }}
            >
              and
            </Typography>
          )}
          {renderSeverity({ count: counts.Info, name: "Info", color: infoColor })}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
          {counts.Error > 0 && counts.Warning > 0 && (
            <FormControlLabel
              control={<Switch checked={hideWarnings} onChange={() => setHideWarnings(!hideWarnings)} color="primary" />}
              label={t`Hide Warnings`}
            />
          )}
          {counts.Warning > 0 && counts.Error > 0 && (
            <FormControlLabel
              control={<Switch checked={hideErrors} onChange={() => setHideErrors(!hideErrors)} color="primary" />}
              label={t`Hide Errors`}
            />
          )}
          {showCalcInconsistencyToggle && (
            <FormControlLabel
              control={<Switch checked={hideRoundingErrors} onChange={() => setHideRoundingErrors(!hideRoundingErrors)} color="primary" />}
              label={t`Hide Rounding Errors`}
            />
          )}
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell data-downloadaspdf={`${pdfGeneratorDataKey}columnheader`}>{t`Severity`}</TableCell>
                <TableCell data-downloadaspdf={`${pdfGeneratorDataKey}columnheader`}>{t`Message`}</TableCell>
                <TableCell data-downloadaspdf={`${pdfGeneratorDataKey}columnheader`}>{t`Knowledge Base Article`}</TableCell>
                <TableCell sx={{ width: 160 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((entry, index) => (
                <TableRow key={index} sx={{ maxWidth: 600 }}>
                  <TableCell sx={{ maxWidth: 180, width: 150 }}>
                    <Typography
                      noWrap
                      sx={{ fontSize: 14 }}
                      color={reportErrorColor(entry.severity)}
                    >
                      <Box display="flex" alignItems="center">
                        {severityIconSelector(entry.severity as PickOnly)}
                        <Typography sx={{ ml: 1, fontSize: 14, fontWeight: "bold" }}>{entry.severity}</Typography>
                      </Box>
                    </Typography>
                  </TableCell>

                  <>{TableCellSeverity(entry.message, 200, `${pdfGeneratorDataKey}message`, entry.leadingText)}</>
                  <TableCell sx={{ maxWidth: 200, width: 200, verticalAlign: "top" }}>
                    <Typography
                      noWrap
                      sx={{ fontSize: 14 }}

                    >
                      {entry.link && (
                        <a target="_blank" rel="noreferrer" href={entry.link} style={{ textDecoration: 'none', color: inconsistencyColor }}>
                          {t`Article Link`}
                        </a>
                      )}
                    </Typography>
                  </TableCell>

                  {entry.elements && entry.elements.length > 0 &&
                    !Object.values(internalValidationErrorIds).includes(entry.id) ? <ErrorElementIcons entry={entry} /> : <TableCell sx={{ width: 160 }} />}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion >
  );
};

export const moreThanOneError = (errors: number, name: PickOnly) => {
  if (errors > 1) {
    if (name === "FatalError") {
      return errors + " " + t`Fatal Errors`;
    } else if (name === "Error") {
      return errors + " " + t`Errors`;
    } else if (name === "Warning") {
      return errors + " " + t`Warnings`;
    } else if (name === "Info") {
      return errors + " " + t`Info`;
    } else if (name === "Inconsistency") {
      return errors + " " + t`Inconsistencies`;
    }
  } else if (errors === 1) {
    if (name === "FatalError") {
      return errors + " " + t`Fatal Error`;
    } else if (name === "Error") {
      return errors + " " + t`Error`;
    } else if (name === "Warning") {
      return errors + " " + t`Warning`;
    } else if (name === "Info") {
      return errors + " " + t`Info`;
    } else if (name === "Inconsistency") {
      return errors + " " + t`Inconsistency`;
    }
  }
};
