import { Stack, TableCell } from "@mui/material";
import { IApiFact, ValidationElement } from "../../api/types";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { t } from "@lingui/macro";
import {
  FactSelectionCaller,
  factSelectionCallerUpdated,
  selectedErrorUpdated,
  selectedFactIdsUpdated,
} from "../../reducers/internalLifeCycleReducer";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useAppSelector";

export interface ErrorElementIconsProps {
  entry: ValidationElement | undefined;
}

const ErrorElementIcons = ({ entry }: ErrorElementIconsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const facts = useAppSelector((state) =>
    state.extract.factType === "standard" ? state.extract.facts : []
  ) as IApiFact[];

  const relevantFacts = facts.filter((fact) =>
    entry?.elements?.some(
      (elem) =>
        (elem.id && fact.contextRef?.contextRefElementId === elem.id) ||
        (elem.id && fact.continuationElementIds?.includes(elem.id)) ||
        (elem.continuedAt &&
          fact.continuationElementIds?.includes(elem.continuedAt)) ||
        (elem.name === fact.contextRef?.name &&
          elem.contextRef === fact.contextRef?.contextRef)
    )
  );

  const handleSelectFact = (
    factIds: string[],
    caller: FactSelectionCaller,
    path: string
  ) => {
    dispatch(selectedFactIdsUpdated(factIds));
    dispatch(factSelectionCallerUpdated(caller));
    if (caller === "summary_viewer") {
      dispatch(selectedErrorUpdated(entry));
    }
    navigate(`/${path}`, { state: { keepSelectedIds: true } });
  };

  return (
    <TableCell sx={{ minWidth: 160, maxWidth: 160 }}>
      {relevantFacts.length > 0 && (
        <Stack direction="row" spacing={2}>
          <Stack direction="column" alignItems="center" sx={{ width: 60 }}>
            <VisibilityIcon
              sx={{ cursor: "pointer" }}
              onClick={() =>
                handleSelectFact(
                  relevantFacts.map((f) => f.id),
                  "summary_viewer",
                  "viewer"
                )
              }
            />
            <span style={{ fontSize: 10, textAlign: "center", cursor: "pointer" }}>{t`Go to HTML Report`}</span>
          </Stack>
          <Stack direction="column" alignItems="center" sx={{ width: 55 }}>
            <FactCheckIcon
              sx={{ cursor: "pointer" }}
              onClick={() =>
                handleSelectFact(
                  relevantFacts.map((f) => f.id),
                  "summary_facts",
                  "facts"
                )
              }
            />
            <span style={{ fontSize: 10, textAlign: "center", cursor: "pointer" }}>{t`Go to Fact list`}</span>
          </Stack>
        </Stack>
      )}
    </TableCell>
  );
};

export default ErrorElementIcons;
