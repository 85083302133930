import {
  DisplayLabel,
  ExtractedElementTree,
  EntryPoint,
  IApiFact,
  ExtractedLinkbaseTree,
  ExtractedLinkbaseTreeElement,
  ExtractedRoleType,
} from "../api/types";
import {
  TreeItemIdentifier,
  getTaxonomyExplorerNodeFlatArray,
} from "./getTaxonomyExplorerNodeFlatArray";
import { padTree } from "./padLinkbaseTree";

export interface MappedLinkbaseTreeItem {
  id: string;
  type: "head" | "roleType" | "elementTree";
  label: DisplayLabel | undefined;
  associatedFacts?: IApiFact[];
  linkbaseTreeElement?: ExtractedLinkbaseTreeElement;
  children: MappedLinkbaseTreeItem[];
  isExtension: boolean;
  hasChildren: boolean | undefined;
  errorLoadingChildren: boolean | undefined;
  backendElementId: string;
  unmappedBranchId: string;
}

export interface MappedLinkebaseTree {
  topBranch: MappedLinkbaseTreeItem;
}

const getAssociatedFacts = (
  unmappedBranch: ExtractedElementTree,
  facts: IApiFact[]
): IApiFact[] | undefined => {
  if (!unmappedBranch.linkbaseTreeElement) return undefined;
  return facts.filter(
    (fact) =>
      fact.factElement?.namespace?.uri ===
        unmappedBranch.linkbaseTreeElement?.namespaceUri &&
      fact.factElement?.elementId ===
        unmappedBranch.linkbaseTreeElement?.elementId
  );
};

const getMainLabel = (
  entryPoints: EntryPoint[] | undefined,
  lang: string
): string => {
  if (!entryPoints || entryPoints.length === 0) return "";
  const entryPoint = entryPoints[0];
  let output = entryPoint.names.find((n) => n.language === lang);
  if (!output) {
    output = entryPoint.names.find((n) => n.language === "en");
  }
  return output?.text || "";
};

export const getTreeItemLabel = (
  labels: DisplayLabel[] | undefined,
  currentLangugae: string
): DisplayLabel | undefined => {
  let label = undefined;
  label = labels?.find((dl) => dl.language === currentLangugae);
  if (!label) {
    label = labels?.find((dl) => dl.language === "en");
  }
  return label;
};

let uniqueIdCounter: { [key: string]: number } = {};

const getItemId = (
  parentBranch: MappedLinkbaseTreeItem,
  unmappedBranch: ExtractedElementTree | ExtractedRoleType,
  currentLanguage: string,
  currentRoleTypeId: string
): string => {
  const type = "roleTypeId" in unmappedBranch ? "roleType" : "elementTree";
  const labels =
    type === "elementTree"
      ? unmappedBranch &&
        (unmappedBranch as ExtractedElementTree).linkbaseTreeElement
        ? (unmappedBranch as ExtractedElementTree).linkbaseTreeElement
            .displayLabels
        : undefined
      : unmappedBranch && (unmappedBranch as ExtractedRoleType).definitionLabels
        ? (unmappedBranch as ExtractedRoleType).definitionLabels
        : undefined;
  const label = getTreeItemLabel(labels, currentLanguage);
  let id;

  if ("roleTypeId" in unmappedBranch) {
    id = (unmappedBranch as ExtractedRoleType).roleTypeId;
  } else {
    if (label && label.label.length > 0) {
      id = `${label.label.replaceAll(" ", "_")}_${
        (unmappedBranch as ExtractedElementTree)?.linkbaseTreeElement?.elementId
      }_${crypto.randomUUID()}_Parent_${parentBranch.id}`;
    } else {
      id = `${currentRoleTypeId}_${unmappedBranch.linkbaseTreeElement?.elementId}_Parent_${parentBranch.id}`;
    }
  }

  if (uniqueIdCounter[id] === undefined) {
    uniqueIdCounter[id] = 0;
  } else {
    uniqueIdCounter[id]++;
    id += `_${uniqueIdCounter[id]}`;
  }

  return id;
};

const mapBranches = (
  parentBranch: MappedLinkbaseTreeItem,
  unmappedBranches: ExtractedRoleType[] | ExtractedElementTree[] | undefined,
  facts: IApiFact[],
  currentLanguage: string,
  parenttPath: string,
  entryPoints: EntryPoint[] | undefined,
  currentRoleTypeId: string,
  currentRoleTypeInternalId: string
): void => {
  if (unmappedBranches) {
    for (const unmappedBranch of unmappedBranches) {
      const type = "roleTypeId" in unmappedBranch ? "roleType" : "elementTree";
      const labels =
        type === "elementTree"
          ? unmappedBranch &&
            (unmappedBranch as ExtractedElementTree).linkbaseTreeElement
            ? (unmappedBranch as ExtractedElementTree).linkbaseTreeElement
                .displayLabels
            : undefined
          : unmappedBranch &&
              (unmappedBranch as ExtractedRoleType).definitionLabels
            ? (unmappedBranch as ExtractedRoleType).definitionLabels
            : undefined;
      const label = getTreeItemLabel(labels, currentLanguage);
      let id = getItemId(
        parentBranch,
        unmappedBranch,
        currentLanguage,
        currentRoleTypeId
      );
      const associatedFacts =
        "roleTypeId" in unmappedBranch
          ? undefined
          : getAssociatedFacts(unmappedBranch, facts);

      let isExtension = false;
      if (type === "elementTree") {
        if (entryPoints && associatedFacts && associatedFacts.length > 0) {
          if (
            associatedFacts.some((f) =>
              entryPoints
                .map((e) => e.namespace.uri)
                .includes(f.factElement.namespace.uri)
            )
          ) {
            isExtension = true;
          }
        }
      }
      const childBranch: MappedLinkbaseTreeItem = {
        children: [],
        id: id,
        type: type,
        associatedFacts: associatedFacts,
        linkbaseTreeElement:
          "roleTypeId" in unmappedBranch
            ? undefined
            : (unmappedBranch as ExtractedElementTree).linkbaseTreeElement,
        label: label,
        isExtension: isExtension,
        hasChildren: unmappedBranch.hasChildren,
        errorLoadingChildren: unmappedBranch.errorLoadingChildren,
        unmappedBranchId: unmappedBranch.id,
        backendElementId:
          "roleTypeId" in unmappedBranch
            ? unmappedBranch.id
            : currentRoleTypeInternalId,
      };

      parentBranch.children.push(childBranch);
      const currentPath = `${parenttPath};${id}`;
      if (
        "roleTypeId" in unmappedBranch &&
        unmappedBranch.elementTrees &&
        unmappedBranch.elementTrees.length > 0
      ) {
        mapBranches(
          childBranch,
          unmappedBranch.elementTrees,
          facts,
          currentLanguage,
          currentPath,
          entryPoints,
          unmappedBranch.roleTypeId,
          unmappedBranch.id
        );
      } else if (
        "roleTypeId" in unmappedBranch === false &&
        (unmappedBranch as ExtractedElementTree).children &&
        (
          (unmappedBranch as ExtractedElementTree)
            .children as ExtractedElementTree[]
        ).length > 0
      ) {
        mapBranches(
          childBranch,
          (unmappedBranch as ExtractedElementTree).children,
          facts,
          currentLanguage,
          currentPath,
          entryPoints,
          "",
          currentRoleTypeInternalId
        );
      }
    }
  }
};

export const mapLinkbaseTreeToTreeviewElement = (
  tree: ExtractedLinkbaseTree | undefined,
  facts: IApiFact[],
  currentSelectedLanguage: string,
  entryPoints: EntryPoint[] | undefined
): {
  tree: MappedLinkebaseTree | undefined;
  flatArray: TreeItemIdentifier[];
} => {
  if (!tree) return { tree: undefined, flatArray: [] };
  const output: {
    tree: MappedLinkebaseTree | undefined;
    flatArray: TreeItemIdentifier[];
  } = {
    tree: {
      topBranch: {
        children: [],
        id: tree.id,
        type: "head",
        label: {
          label: getMainLabel(entryPoints, currentSelectedLanguage),
          language: "en",
          role: "",
        },
        isExtension: false,
        hasChildren: tree.roleTypes && tree.roleTypes.length > 0,
        errorLoadingChildren: false,
        backendElementId: "",
        unmappedBranchId: "",
      },
    },
    flatArray: [],
  };
  mapBranches(
    (output.tree as MappedLinkebaseTree).topBranch,
    tree.roleTypes,
    facts,
    currentSelectedLanguage,
    "",
    entryPoints,
    "",
    ""
  );
  padTree(output.tree);
  output.flatArray = getTaxonomyExplorerNodeFlatArray(output.tree);
  uniqueIdCounter = {};
  return output;
};
