import { PropsWithChildren, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

import { ReactComponent as HtmlIcon } from "../../images/icons/file-code-solid.svg";
import { ReactComponent as FileIcon } from "../../images/icons/file-solid.svg";
import { ReactComponent as PdfIcon } from "../../images/icons/pdfPage.svg";
import { ReactComponent as XlxsIcon } from "../../images/icons/xlsxPage.svg";
import FolderZipIcon from "@mui/icons-material/FolderZip";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import LoadingButton from "@mui/lab/LoadingButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import AttachFile from "@mui/icons-material/AttachFile";
import UploadFile from "@mui/icons-material/UploadFile";
import { t, Trans } from "@lingui/macro";
import { acceptedFileTypes, calculationTypes } from "../../helpers/constants";
import { Alert, Container, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, TextField } from "@mui/material";
import { validateAgainstExistingFiles } from "../../helpers/dropzone.validation";
import {
  organizationHasConvertor,
  organizationHasInspector,
} from "../../helpers/tokenDecoder";
import tokenHelper from "../../helpers/tokenHelper";
import { ImportAction } from "../../reducers/importReducer";
import { getImportActionDataType } from "../../helpers/getImportActionDataType";
import { areFilesForConversion } from "../../helpers/isConversion";

interface DropZoneProps extends PropsWithChildren {
  files: File[] | undefined;
  showSaveArtifactsCheckbox: boolean;
  isLoading: boolean;
  onFilesAdded: (files: File[]) => void;
  onFileRemoved: (index: number) => void;
  onStartImportAction: (
    actions: ImportAction[],
    shouldSaveArtifacts?: boolean,
    pageRange?: string,
  ) => void;
  calculationType: string;
  onCalculationTypeChange: (calculationType: string) => void;
}

// Wraps the react-dropzone component to provide a Parseport styled dropzone with a file list preview
// of what will be converted.
const Dropzone = ({
  files,
  isLoading,
  onFileRemoved,
  onFilesAdded,
  onStartImportAction,
  showSaveArtifactsCheckbox,
  children,
  calculationType,
  onCalculationTypeChange,
}: DropZoneProps) => {
  // Track the save artifacts checkbox state so it can be sent to props.onStartConversion
  const [saveArtifacts, setSaveArtifacts] = useState(false);
  const [fileImportError, setFileImportError] = useState("");
  const [pageRange, setPageRange] = useState<string | null>(null);
  const userHasConvertor = organizationHasConvertor(tokenHelper.token);
  const userHasInspector = organizationHasInspector(tokenHelper.token);

  const handleFilesRejected = (fileRejections: FileRejection[]) => {
    if (
      fileRejections &&
      fileRejections.length > 0 &&
      fileRejections[0].errors &&
      fileRejections[0].errors.length > 0
    ) {
      setFileImportError(fileRejections[0].errors[0].message);
    }
  };

  // Setup dropzone
  const {
    getRootProps,
    getInputProps,
    open: openFileInput,
    isDragActive,
  } = useDropzone({
    onDrop: onFilesAdded,
    noClick: true,
    noKeyboard: true,
    disabled: isLoading,
    useFsAccessApi: false,
    accept: acceptedFileTypes(userHasInspector, userHasConvertor),
    onDropRejected: handleFilesRejected,
    validator: (file: File) => validateAgainstExistingFiles(file, files),
  });

  const handleOpenButtonClicked = () => {
    openFileInput();
    setFileImportError("");
  };

  // Render the attached files below the dropzone
  function renderFileList() {
    return (
      <Stack spacing={0.3} style={{ maxHeight: 300, overflow: "auto" }}>
        {files?.map((file, index) => {
          const icon = getIconForFileType(file.type);
          return (
            <ListItem
              key={index}
              sx={{ backgroundColor: "white", borderRadius: 1 }}
              secondaryAction={
                <IconButton
                  disabled={isLoading}
                  onClick={() => onFileRemoved(index)}>
                  <CloseIcon />
                </IconButton>
              }>
              {icon}
              <Typography
                variant="body1"
                sx={{
                  ml: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {file.name}
              </Typography>
            </ListItem>
          );
        })}
      </Stack>
    );
  }

  const handleFireImportActions = (
    isConversion: boolean,
    importDataType: ImportAction["dataType"],
    withValidation: boolean,
    saveArtifacts: boolean,
    pageRange?: string,
  ) => {
    const actions: ImportAction[] = [];
    if (isConversion) {
      actions.push({
        dataType: importDataType,
        type: "conversion",
      });
    }
    if (importDataType === "xbrlOnly") {
      actions.push({
        dataType: importDataType,
        type: "generation",
      });
    }
    if (withValidation) {
      actions.push({
        dataType: importDataType,
        type: "validation",
      });
    }
    if (importDataType !== "pdfOnly" && importDataType !== "epubOnly") {
      actions.push({
        dataType: importDataType,
        type: "extraction",
      });
    }

    onStartImportAction(actions, saveArtifacts, pageRange || undefined);
  };

  const importDataType = getImportActionDataType(files);
  const isConversion = areFilesForConversion(files || []);

  return (
    <>
      <Box>
        <Paper variant="outlined" sx={{ p: 3, mb: 2, textAlign: "center" }}>
          <Box
            sx={{
              p: 3,
              border: "1px dashed rgba(0,0,0,.912);",
              minHeight: "410px",
            }}
            {...getRootProps({ className: "dropzone" })}>
            <Box sx={{ pb: 2 }}>
              <Typography paragraph={true} sx={{ mb: 7, fontSize: "2.5rem" }}>
                <Trans>IMPORT</Trans>
              </Typography>
              <input {...getInputProps()} />

              <UploadFile fontSize="large" />

              <Typography paragraph={true} variant="body1">
                {isDragActive ? (
                  <Trans>File(s) in drop zone</Trans>
                ) : (
                  <Trans>Drag & Drop</Trans>
                )}
              </Typography>
              <Typography paragraph={true} variant="body1">
                <Trans>OR</Trans>
              </Typography>
              <Button
                variant="contained"
                color="inherit"
                disabled={isLoading}
                startIcon={<AttachFile />}
                onClick={handleOpenButtonClicked}>
                <Trans>Browse Files</Trans>
              </Button>
            </Box>

            <Box sx={{ pb: 2, display: "flex", justifyContent: "center" }}>
              <>
                {!files ||
                  (files.length === 0 && (
                    <Typography paragraph={true} variant="body1">
                      <Trans>To get started</Trans>
                    </Typography>
                  ))}
                {files && files.length > 0 && (
                  <Container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <FormGroup>
                      {showSaveArtifactsCheckbox && isConversion ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isLoading || !files || files.length === 0}
                              onChange={(e) => setSaveArtifacts(e.target.checked)}
                            />
                          }
                          label={t`Save debug artifacts`}
                        />
                      ) : null}
                      <LoadingButton
                        data-insights-id={
                          isConversion ? "ConvertButton" : "ImportButton"
                        }
                        disabled={isLoading}
                        variant="outlined"
                        loading={isLoading}
                        loadingIndicator={
                          isConversion ? t`Converting...` : t`Opening...`
                        }
                        sx={{ mb: 1 }}
                        onClick={() =>
                          handleFireImportActions(
                            isConversion,
                            importDataType,
                            false,
                            saveArtifacts,
                            pageRange || undefined,
                          )
                        }>
                        {isConversion && <Trans>Convert</Trans>}
                        {!isConversion && <Trans>Open</Trans>}
                      </LoadingButton>
                      <LoadingButton
                        data-insights-id={
                          isConversion
                            ? "ConvertAndValidateButton"
                            : "ImportAndValidateButton"
                        }
                        disabled={isLoading}
                        variant="contained"
                        loading={isLoading}
                        loadingIndicator={
                          isConversion ? t`Converting...` : t`Validating...`
                        }
                        onClick={() =>
                          handleFireImportActions(
                            isConversion,
                            importDataType,
                            true,
                            saveArtifacts,
                            pageRange || undefined,
                          )
                        }>
                        {isConversion && <Trans>Convert and validate</Trans>}
                        {!isConversion && <Trans>Open and validate</Trans>}
                      </LoadingButton>
                    </FormGroup>
                    <FormControl sx={{ mt: 5, minWidth: 180, maxWidth: 180 }}>
                      <InputLabel
                        variant="outlined"
                        htmlFor="controlled-select"
                      >
                        {t`Calculation Type`}
                      </InputLabel>
                      <Select
                        labelId="controlled-select-label"
                        id="controlled-select"
                        value={calculationType}
                        onChange={(event) => onCalculationTypeChange(event.target.value as string)}
                        input={<OutlinedInput label={t`Calculation Type`} />}
                        fullWidth
                      >
                        {Object.entries(calculationTypes).map(([key, label]) => (
                          <MenuItem key={key} value={key}>
                            {t`${label}`}
                          </MenuItem>
                        ))}
                      </Select>
                      {isConversion && files.some(file => file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                        <TextField
                          label={t`Page Range`}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          disabled={isLoading}
                          type="text"
                          value={pageRange !== null ? pageRange : ""}
                          onChange={(e) => setPageRange(e.target.value)}
                          onBlur={(e) => e.target.removeAttribute('helperText')}
                        />
                      )}
                    </FormControl>
                    {isConversion && files.some(file => file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                      <Typography variant="body2" color="textSecondary">
                        {t`Page range will specify what pages you wish converted. Example: 1-25 will convert from 1 to 25, and writing just 25 will only convert that page.`}
                      </Typography>
                    )}
                  </Container>
                )}
              </>
            </Box>
            {children}
          </Box>
        </Paper >
        {renderFileList()}
      </Box >
      <Snackbar
        open={fileImportError.length > 0}
        onClose={() => setFileImportError("")}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity="error">{fileImportError}</Alert>
      </Snackbar>
    </>
  );
};

// Return an appropriate icon for the given file mime type
const getIconForFileType = (fileType: string) => {
  switch (fileType) {
    case Object.keys(acceptedFileTypes).find(
      (key) => key.toLowerCase().indexOf("pdf") > -1
    ):
      return <PdfIcon />;
    case Object.keys(acceptedFileTypes).find(
      (key) => key.toLowerCase().indexOf("spreadsheetml") > -1
    ):
      return <XlxsIcon />;
    case Object.keys(acceptedFileTypes).find(
      (key) => key.toLowerCase().indexOf("html") > -1
    ):
      return <HtmlIcon />;
    case Object.keys(acceptedFileTypes).find(
      (key) => key.toLowerCase().indexOf("zip") > -1
    ):
      return <FolderZipIcon />;
    default:
      return <FileIcon />;
  }
};

export default Dropzone;