import { TableCell, Typography } from "@mui/material";
import React from "react";
export const TableCellSeverity = (
  title: string[] | string,
  length: number,
  pdfGeneratorDataKey: string,
  leadingText?: string
) => {


  const formatLeadingText = (text: string) => {
    if (!text.includes('[') || !text.includes(']')) {
      return text;
    }
    return text.split('[').map((part, index) => {
      if (index === 0) return part;
      const [boldText, rest] = part.split(']');
      return (
        <React.Fragment key={index}>
          <Typography component="span" sx={{ fontWeight: 'bold', fontSize: 12 }}>
            [{boldText}]
          </Typography>
          {rest} <br />
        </React.Fragment>
      );
    });
  };

  return (
    <TableCell sx={{ maxWidth: length }}>
      <Typography
        data-downloadaspdf={pdfGeneratorDataKey}
        sx={{
          fontSize: 12,
          color: "black",
        }}>
        {leadingText && formatLeadingText(leadingText)}
        {Array.isArray(title) ? title.map((t, index) => (
          <React.Fragment key={index}>
            <Typography variant="subtitle2" component="span" sx={{ fontSize: 12 }}>
              {t}
            </Typography>
            <br />
          </React.Fragment>
        )) : formatLeadingText(title)}
      </Typography>
    </TableCell>
  );
};
