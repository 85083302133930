import { t } from "@lingui/macro";
import { IApiEurofilingFact, IApiFact, ValidationElement } from "../api/types";

import { internalValidationErrorIds, internalValidationUrlPrefix } from "./constants";
import { IExtractionState } from "../reducers/extractionReducer";

export const getInternalValidationEntriesForFacts = (
  facts: Array<IApiFact | IApiEurofilingFact>,
  extractedFactsType: IExtractionState["factType"]
): ValidationElement[] => {
  const entries: ValidationElement[] = [];
  for (const fact of facts) {
    entries.push(
      ...getInternalValidationEntriesForFact(fact, facts, extractedFactsType)
    );
  }
  return entries;
};

export const getInternalValidationEntriesForFact = (
  fact: IApiFact | IApiEurofilingFact,
  allOtherFacts: Array<IApiFact | IApiEurofilingFact>,
  extractedFactsType: IExtractionState["factType"]
): ValidationElement[] => {
  const entries: ValidationElement[] = [];
  if (extractedFactsType === "standard") {
    const standarsFact = fact as IApiFact;
    if (standarsFact.contextRef?.isHidden) {
      if (standarsFact.contextRef.format) {
        entries.push({
          id: internalValidationErrorIds.hiddenTransformable,
          leadingText:
            "[(Partially)HiddenTransformableFact] The report contains styling that causes these facts or parts of these transformable facts to be hidden:",
          severity: "Error",
          code: "(Partially)HiddenTransformableFact",
          isRoundingError: false,
          link: `${internalValidationUrlPrefix}error-partiallyhiddentransformablefact`,
          elements: [
            {
              contextRef: standarsFact.contextRef?.contextRef,
              name: standarsFact.contextRef?.name,
              continuedAt: "",
              id: standarsFact.contextRef?.contextRefElementId || "",
            },
          ],
          message: t`${standarsFact.factElement.name}, context: ${standarsFact.contextRef.contextRef}`,
        });
      } else {
        entries.push({
          leadingText:
            "[(Partially)HiddenFact] The report contains styling that causes these facts or parts of these facts to be hidden:",
          severity: "Warning",
          code: "(Partially)HiddenFact",
          isRoundingError: false,
          link: `${internalValidationUrlPrefix}warning-partiallyhiddenfact`,
          elements: [
            {
              contextRef: standarsFact.contextRef?.contextRef,
              name: standarsFact.contextRef?.name,
              continuedAt: "",
              id: standarsFact.contextRef?.contextRefElementId || "",
            },
          ],
          id: internalValidationErrorIds.hidden,
          message: t`${standarsFact.factElement.name}, context: ${standarsFact.contextRef.contextRef}`,
        });
      }
    }
    if (
      !isNaN(parseInt(standarsFact.formattedValue)) &&
      standarsFact.contextRef?.target
    ) {
      if (
        (allOtherFacts as IApiFact[]).some(
          (of) =>
            of.contextRef.contextRef === standarsFact.contextRef.contextRef &&
            of.factElement.name === standarsFact.factElement.name &&
            of.contextRef.unitRef === standarsFact.contextRef.unitRef &&
            of.contextRef.language === standarsFact.contextRef.language &&
            of.formattedValue !== standarsFact.formattedValue
        )
      ) {
        entries.push({
          leadingText:
            "[InconsistentDuplicateNonEsefFact] The following contain inconsistent duplicate non-numeric facts which SHOULD NOT appear in the content of an inline XBRL document:",
          severity: "Warning",
          isRoundingError: false,
          code: "InconsistentDuplicateNonEsefFact",
          elements: [
            {
              contextRef: standarsFact.contextRef?.contextRef,
              name: standarsFact.contextRef?.name,
              continuedAt: "",
              id: standarsFact.contextRef?.contextRefElementId || "",
            },
          ],
          id: internalValidationErrorIds.inconsistentDuplicate,
          message: t`${standarsFact.factElement.name}, context: ${standarsFact.contextRef.contextRef}`,
          isHighlightable: true,
        });
      }
    }
    if (
      !isNaN(parseInt(standarsFact.contextRef?.decimals)) &&
      !isNaN(standarsFact.contextRef?.scale)
    ) {
      if (
        -1 * parseInt(standarsFact.contextRef.decimals) >
        standarsFact.contextRef.scale
      ) {
        entries.push({
          leadingText: `[DecimalHigherThanScale] The following facts contain a decimal property with a value of ${standarsFact.contextRef.decimals}, which makes the calculation margin bigger than the scale ${standarsFact.contextRef.scale}, which will ignore any inconsistencies as a result:`,
          severity: "Warning",
          isRoundingError: false,
          code: "DecimalHigherThanScale",
          elements: [
            {
              contextRef: standarsFact.contextRef?.contextRef,
              name: standarsFact.contextRef?.name,
              continuedAt: "",
              id: standarsFact.contextRef?.contextRefElementId || "",
            },
          ],
          id: internalValidationErrorIds.inconsistentDecimal,
          message: t`${standarsFact.factElement.name}, context: ${standarsFact.contextRef.contextRef}`,
          isHighlightable: true,
        });
      }
    }
    if (/[\uE000-\uF8FF]/.test(standarsFact.formattedValue)) {
      entries.push({
        leadingText:
          "[FactContainsUnreadableCharacters] The following facts contain characters that cannot be read by a browser:",
        severity: "Warning",
        isRoundingError: false,
        code: "FactContainsUnreadableCharacters",
        link: `${internalValidationUrlPrefix}warning-factcontainsunreadablecharacters`,
        elements: [
          {
            contextRef: standarsFact.contextRef?.contextRef,
            name: standarsFact.contextRef?.name,
            continuedAt: "",
            id: standarsFact.contextRef?.contextRefElementId || "",
          },
        ],
        id: internalValidationErrorIds.unicodeChar,
        message: t`${standarsFact.factElement.name}, context: ${standarsFact.contextRef.contextRef}`,
        isHighlightable: false,
      });
    }
  } else {
    const eurofilingFact = fact as IApiEurofilingFact;
    if (/[\uE000-\uF8FF]/.test(eurofilingFact.value)) {
      entries.push({
        leadingText:
          "[FactContainsUnreadableCharacters] The following facts contain characters that cannot be read by a browser:",
        severity: "Warning",
        isRoundingError: false,
        code: "FactContainsUnreadableCharacters",
        link: `${internalValidationUrlPrefix}warning-factcontainsunreadablecharacters`,
        elements: [
          {
            contextRef: "",
            name: "",
            continuedAt: "",
            id: eurofilingFact.id,
          },
        ],
        id: internalValidationErrorIds.unicodeChar,
        message: t`${eurofilingFact.tableName}_${eurofilingFact.id}`,
        isHighlightable: false,
      });
    }
  }
  // eslint-disable-next-line
  return entries;
};
