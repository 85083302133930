import { ValidationElement } from "../api/types";

export const getUniqueErrorMessages = (
  data: ValidationElement[]
): ValidationElement[] => {
  const uniqueData: {
    [key: string]: ValidationElement;
  } = {};
  for (const entry of data) {
    if (!uniqueData[entry.code]) {
      uniqueData[entry.code] = {
        code: entry.code,
        id: entry.id,
        message: Array.isArray(entry.message) ? entry.message : [entry.message],
        isRoundingError: entry.isRoundingError,
        severity: entry.severity,
        elements: entry.elements,
        leadingText: entry.leadingText,
        link: entry.link,
      };
    } else {
      const messages = Array.isArray(entry.message)
        ? entry.message
        : [entry.message];
      for (const message of messages) {
        if (!uniqueData[entry.code].message.includes(message)) {
          uniqueData[entry.code].message = [
            ...uniqueData[entry.code].message,
            message,
          ];
        }
      }
    }
  }

  const uniqueDataArray: ValidationElement[] = Object.values(uniqueData);
  return uniqueDataArray;
};
